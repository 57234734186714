
import { Vue, Component, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import { getChildAssets, updateAssetProperties } from "@/config/asset";
import { DecoratedAsset, GqlAsset } from "@/types";
import OutdoorUnit from "./OutdoorUnit.vue";
import { deviceLock } from "@/gql/fragments/deviceLock";

@Component({
  components: {
    OutdoorUnit
  },
  apollo: {
    assetWithChildren: {
      query: gql`
        ${deviceLock}
        query AssetQuery($assetUuid: ID!) {
          assetWithChildren: asset(assetUuid: $assetUuid) {
            childLinks {
              linkType
              childAsset {
                assetUuid
                knownAssetUuid
                name
                smart
                serialNumber
                manufacturer {
                  name
                }
                assetModel {
                  name
                }
                ... on Device {
                  properties
                  settings
                  miscFields
                  thresholds {
                    values
                  }
                  ...DeviceLockFields
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          assetUuid: this.$route.params.assetUuid
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    },
    $subscribe: {
      assetDataChanges: {
        query: gql`
          subscription AssetDataChanges($assetUuids: [ID!]!, $authorization: String!) {
            assetDataChanges(assetUuids: $assetUuids, authorization: $authorization) {
              assetUuid
              property
              stamp
              value
            }
          }
        `,
        variables() {
          return {
            assetUuids: this.indoorUnits.map((iu: DecoratedAsset) => iu.assetUuid),
            authorization: this.$store.getters.accessToken
          };
        },
        skip() {
          return this.indoorUnits.length === 0;
        },
        result({ data }: Record<string, any>) {
          if (this.assetWithChildren && data.assetDataChanges) {
            updateAssetProperties(this.indoorUnits, data.assetDataChanges);
          }
        }
      }
    }
  }
})
export default class DeviceDashboardTab extends Vue {
  @Prop({ type: Object, required: true })
  readonly asset: DecoratedAsset;

  assetWithChildren: GqlAsset | null = null;

  get indoorUnits(): DecoratedAsset[] {
    if (this.assetWithChildren === null) return [];
    const childAssets = getChildAssets(this.assetWithChildren, { linkType: "VRF_OU_IU" });
    return orderBy(childAssets, "properties.iu_unit_addr.value");
  }
}
